// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/general/HomeView.vue'
import AuthCallback from '@/views/general/auth/AuthCallback.vue'
import LoggedOut from '@/views/general/auth/LoggedOut.vue'
import Unauthorized from '@/views/general/auth/Unauthorized.vue'
import NoPermissionView from '@/views/general/NoPermissionView.vue'
import { useOAuthService } from '@/services/OAuthService.js'
import LoginPage from '@/views/general/LoginPage.vue'
import BuildingView from '@/views/general/BuildingView.vue'
import BookingView from '@/views/general/BookingView.vue'
import LocksView from '@/views/general/LocksView.vue'
import MenuView from '@/views/general/MenuView.vue'
import PoisListView from '@/views/general/PoisListView.vue'
import InfoCenterView from '@/views/general/InfoCenterView.vue'
import UserBookings from '@/components/general/UserBookings.vue'
import RoomControlPois from '@/components/general/RoomControlPois.vue'
import { getBookingPoisListComponent } from './routerHelper'
import CreateEditBooking from '@/components/general/CreateEditBooking.vue'
import MarketingView from '@/views/general/MarketingView.vue'
import RoomControl from '@/components/general/RoomControl.vue'
import Vouchers from '@/components/general/Vouchers.vue'
import ChatUsersList from '@/components/general/ChatUsersList.vue'
import Chat from '@/components/general/Chat.vue'
import Elevator from '@/components/general/Elevator.vue'
import AnalyticsList from '@/components/general/AnalyticsList.vue'
import OfficeAnalytics from '@/components/general/OfficeAnalytics.vue'
import OccupancyAnalytics from '@/components/general/OccupancyAnalytics.vue'
import BookingsManagement from '@/components/general/BookingsManagement.vue'
import PoiDetails from '@/components/general/PoiDetails.vue'
import FeedbackForm from '@/components/general/FeedbackForm.vue'
import UserProfile from '@/components/general/UserProfile.vue'
import Incidents from '../components/general/Incidents.vue'
import CreateEditInfoItem from '@/components/general/ChildComponents/CreateEditInfoItem.vue'
import Functions from '@/components/general/Functions.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    redirect: '/infocenter',
    meta: { requiresAuth: true },
  },
  {
    path: '/building',
    name: 'BuildingView',
    component: BuildingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/marketing',
    name: 'MarketingView',
    component: MarketingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'ChatUsersList',
    component: ChatUsersList,
    meta: { requiresAuth: true },
  },
  {
    path: '/users/:email',
    name: 'Chat',
    component: Chat,
    meta: { requiresAuth: true },
  },
  {
    path: '/elevator',
    name: 'Elevator',
    component: Elevator,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/analytics',
    name: 'AnalyticsList',
    component: AnalyticsList,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/analytics/officedays',
    name: 'OfficeAnalytics',
    component: OfficeAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/analytics/occupancy',
    name: 'OccupancyAnalytics',
    component: OccupancyAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/incidents',
    name: 'Incidents',
    component: Incidents,
    meta: { requiresAuth: true },
  },

  {
    path: '/booking',
    name: 'BookingView',
    component: BookingView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'pois-list',
        name: 'BookingPoisList',
        component: () => getBookingPoisListComponent(), // Dynamic import based on customer
        meta: { requiresAuth: true },
      },
      {
        path: 'user-bookings',
        component: UserBookings,
        name: 'UserBookings',
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/menu/booking-management',
    name: 'BookingsManagement',
    component: BookingsManagement,
    meta: { requiresAuth: true },
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/building/:building_id/:category_id/poi/:poi_id/create_booking',
    name: 'createManageBooking',
    component: CreateEditBooking,
    meta: { requiresAuth: true },
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/building/:building_id/:category_id/poi/:poi_id/edit_booking/:reservation_id/:appointment_id',
    name: 'editManageBooking',
    component: CreateEditBooking,
    meta: { requiresAuth: true },
  },
  {
    path: '/building/:building_id/:category_id/pois-list',
    name: 'PoisListView',
    component: PoisListView,
    meta: { requiresAuth: true },
  },
  {
    path: '/building/:building_id/:category_id/:poi_id',
    name: 'PoiDetails',
    component: PoiDetails,
    meta: { requiresAuth: true },
  },

  {
    path: '/locks',
    name: 'LocksView',
    component: LocksView,
    meta: { requiresAuth: true },
  },
  {
    path: '/infocenter',
    name: 'InfoCenterView',
    component: InfoCenterView,
    meta: { requiresAuth: true },
  },
  {
    path: '/create-edit-info-item',
    name: 'CreateEditInfoItem',
    component: CreateEditInfoItem,
    meta: { requiresAuth: true },
  },

  {
    path: '/menu',
    name: 'MenuView',
    component: MenuView,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/feedback',
    name: 'FeedbackForm',
    component: FeedbackForm,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/feedbacks/admin',
    name: 'FeedbacksListAdmin',
    component: FeedbackForm,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/room-control',
    component: RoomControlPois,
    name: 'RoomControlPois',
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/room-control/:poi_id',
    component: RoomControl,
    name: 'RoomControl',
    meta: { requiresAuth: true },
  },

  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { public: true },
  },
  {
    path: '/oauth2/code/callback',
    name: 'auth-callback',
    component: AuthCallback,
    meta: { public: true },
  },
  {
    path: '/unauthorized-email',
    name: 'unauthorized',
    component: Unauthorized,
    meta: { public: true },
  },
  {
    path: '/logged-out',
    name: 'logged-out',
    component: LoggedOut,
    meta: { public: true },
  },
  {
    path: '/no-permission',
    name: 'no-permission',
    component: NoPermissionView,
    meta: { requiresAuth: true },
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: Vouchers,
    meta: { requiresAuth: true },
  },
  {
    path: '/functions',
    name: 'Functions',
    component: Functions,
    meta: { requiresAuth: true },
  },
  // Define other routes as needed...
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Fixed typo here
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authService = useOAuthService()
  const loggedIn = await authService.isLoggedIn()

  // If route is public or user is logged in, continue
  if (to.meta.public || loggedIn) {
    next()
    return
  }

  // Store the route user wanted to access
  if (!loggedIn && to.name !== 'auth-callback') {
    // Store the full path in local storage or a global state
    localStorage.setItem('redirectAfterLogin', to.fullPath)
    next({ name: 'Login' })
    return
  }

  next()
})

export default router

import axios from './axiosWrapper' // Ensure you import the custom axios instance
import { useOAuthService } from '@/services/OAuthService'
import { loadConfig } from '@/configLoader'
import { Device } from '@capacitor/device'
import { Preferences } from '@capacitor/preferences'
import { parseJwt } from '@/services/utils'
import * as Flatted from 'flatted'
import { storeBlob, retrieveBlob } from '@/services/utils'
import moment from 'moment'
import lodash from 'lodash'

async function isWebPlatform() {
  const { platform } = await Device.getInfo()
  return platform === 'web'
}

const getBaseUrl = async () => {
  let envConfig = await loadConfig()
  return envConfig.apiUrl
}
const getApiKey = async () => {
  let envConfig = await loadConfig()
  return envConfig.apiKey
}
const getFileUrl = async () => {
  let envConfig = await loadConfig()
  return envConfig.fileUrl
}
export const getFileApiKey = async () => {
  let envConfig = await loadConfig()
  return envConfig.fileApiKey
}

export const getAlternateFileUrl = async () => {
  let envConfig = await loadConfig()
  return envConfig.alternateFileUrl
}

export const isStaingOrQa = async () => {
  let apiKey = await getApiKey()
  return apiKey.includes('qa') || apiKey.includes('staging')
}

export const getCampusId = async () => {
  const userProfile = await oAuthService.getUserProfile()
  let campuses = await getCampuses()
  let apiKey = await getApiKey()
  let selectedCampus = {}
  if (apiKey?.includes('qa') || apiKey?.includes('staging')) {
    selectedCampus = campuses.find(campus => campus.name === 'Main Site' || campus.name === 'Hauptstandort')
    userProfile['campus-id'] = selectedCampus.id
  }

  return userProfile['campus-id']
}

export const getCampuses = async () => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'campuses'
  config.url = `${baseUrl}${customBase}`
  try {
    const response = await axios(config)
    return response.data.items
  } catch (error) {
    console.error('Error fetching campuses:', error)
    throw error
  }
}
async function storeData(key, data) {
  if (data instanceof Blob) {
    // Use IndexedDB for blobs
    await storeBlob(key, data)
  } else {
    // Use Flatted for other types of data
    const stringifiedData = Flatted.stringify(data)
    if (await isWebPlatform()) {
      localStorage.setItem(key, stringifiedData)
    } else {
      await Preferences.set({ key, value: stringifiedData })
    }
  }
}
export const getUserProfiles = async () => {
  const storageKey = 'userProfiles'

  // Check if data exists in IndexedDB (or other storage)
  let userProfiles = await retrieveBlob(storageKey)
  if (!userProfiles) {
    // Fetch from API if not found in storage
    const config = await getConfig()
    const baseUrl = await getBaseUrl()
    const customBase = 'userprofiles'
    config.url = `${baseUrl}${customBase}`

    try {
      const response = await axios(config)
      userProfiles = response.data.items

      // Store fetched data in IndexedDB
      await storeBlob(storageKey, userProfiles)
    } catch (error) {
      console.error('Error fetching user profiles:', error)
      throw error
    }
  }

  return userProfiles
}

export const getUserProfileById = async id => {
  const config = await getConfig()
  const baseUrl = await getBaseUrl()
  const customBase = `userprofiles/${id}`
  config.url = `${baseUrl}${customBase}`
  let userProfile
  try {
    const response = await axios(config)
    userProfile = response.data
  } catch (error) {
    console.error('Error fetching user profile:', error)
    throw error
  }
  return userProfile
}

export const getChatById = async chatId => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'one-to-one-chats/' + chatId
  config.url = `${baseUrl}${customBase}`
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error fetching chat by ID:', error)
    throw error
  }
}

export const deleteChatById = async chatId => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'one-to-one-chat-items/' + chatId
  config.url = `${baseUrl}${customBase}`
  config.method = 'DELETE'
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error deleting chat by ID:', error)
    throw error
  }
}

export const updateChatById = async (chatId, messagePayload) => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'one-to-one-chat-items/' + chatId
  config.url = `${baseUrl}${customBase}`
  config.method = 'PUT'
  config.data = messagePayload
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error updating chat by ID:', error)
    throw error
  }
}

export const getAllChats = async () => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'one-to-one-chats?offset=0&count=10'
  config.url = `${baseUrl}${customBase}`
  try {
    const response = await axios(config)
    return response.data.items
  } catch (error) {
    console.error('Error fetching all chats:', error)
    throw error
  }
}

export const getChatWithUser = async userId => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'one-to-one-chats?partnerProfileId=' + userId
  config.url = `${baseUrl}${customBase}`
  try {
    const response = await axios(config)
    return response.data.items
  } catch (error) {
    console.error('Error fetching chat with user:', error)
    throw error
  }
}

export const postChatMessage = async (userId, message) => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'one-to-one-chat-items'
  config.url = `${baseUrl}${customBase}`
  config.method = 'POST'
  config.data = {
    recipientProfileId: userId,
    message: message,
  }
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error posting chat message:', error)
    throw error
  }
}

async function getStoredData(key) {
  if (await isWebPlatform()) {
    const item = localStorage.getItem(key)
    return item ? Flatted.parse(item) : await retrieveBlob(key) // Try to get a blob if parsing fails
  } else {
    const { value } = await Preferences.get({ key })
    return value ? Flatted.parse(value) : await retrieveBlob(key)
  }
}
export const removeStoredItem = async key => {
  if (await isWebPlatform()) {
    localStorage.removeItem(key)
  } else {
    await Preferences.remove({ key })
  }
}

const oAuthService = useOAuthService()

const getConfig = async () => {
  const token = await oAuthService.getToken()

  return {
    method: 'GET',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-ps-api-key': await getApiKey(),
    },
  }
}

export const getInfoItems = async () => {
  const storageKey = 'infoItems'
  let data = await getStoredData(storageKey)
  if (!data) {
    const config = await getConfig()
    const campusId = await getCampusId()
    config.url = `${await getBaseUrl()}campuses/${campusId}/infoitems`
    try {
      const response = await axios(config)
      data = response.data.items
      await storeData(storageKey, data)
    } catch (error) {
      console.error('Error fetching info items:', error)
      throw error
    }
  }
  return data
}

export const createInfoItem = async payload => {
  const config = await getConfig()
  const campusId = await getCampusId()
  config.url = `${await getBaseUrl()}campuses/${campusId}/infoitems`
  config.method = 'POST'
  config.data = payload
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error creating info item:', error)
    throw error
  }
}

export const deleteInfoItem = async id => {
  const config = await getConfig()
  const campusId = await getCampusId()
  config.url = `${await getBaseUrl()}campuses/${campusId}/infoitems/${id}`
  config.method = 'DELETE'
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error deleting info item:', error)
    throw error
  }
}

export const linkInfoItemToCategory = async (infoItemId, categoryId) => {
  const config = await getConfig()
  config.url = `${await getBaseUrl()}infoitems/${infoItemId}/infocategory`
  config.method = 'POST'
  config.data = `${categoryId}`
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error linking info item to category:', error)
    throw error
  }
}

export const linkInfoItemTitleImage = async (infoItemId, fileId) => {
  const config = await getConfig()
  const campusId = await getCampusId()
  config.url = `${await getBaseUrl()}campuses/${campusId}/infoitems/${infoItemId}/files`
  config.method = 'POST'
  config.data = { files: [{ id: fileId, type: 'titleimage' }] }
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error linking info item title image:', error.response?.data || error.message)
    throw error
  }
}
export const linkInfoTitleExtraImages = async (infoItemId, fileIdsArray) => {
  const config = await getConfig()
  const campusId = await getCampusId()
  config.url = `${await getBaseUrl()}campuses/${campusId}/infoitems/${infoItemId}/files`
  config.method = 'POST'
  config.data = { files: fileIdsArray.map(fileId => ({ id: fileId, type: 'image' })) }
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error linking info item extra images:', error.response?.data || error.message)
    throw error
  }
}
export const linkInfoItemAttachments = async (infoItemId, fileIdsArray) => {
  const config = await getConfig()
  const campusId = await getCampusId()
  config.url = `${await getBaseUrl()}campuses/${campusId}/infoitems/${infoItemId}/files`
  config.method = 'POST'
  config.data = { files: fileIdsArray.map(fileId => ({ id: fileId, type: 'attachment' })) }
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error linking info item attachments:', error.response?.data || error.message)
    throw error
  }
}

export const getInfoItemById = async id => {
  const storageKey = `infoItem_${id}`
  let data = await getStoredData(storageKey)
  if (!data) {
    const config = await getConfig()
    const campusId = await getCampusId()
    config.url = `${await getBaseUrl()}campuses/${campusId}/infoitems/${id}`
    try {
      const response = await axios(config)
      data = response.data
      await storeData(storageKey, data)
    } catch (error) {
      console.error('Error fetching info item:', error)
      throw error
    }
  }
}

export const getInfoCategories = async () => {
  const storageKey = 'infoCategories'
  let data = await getStoredData(storageKey)
  if (!data) {
    const campusId = await getCampusId()
    const config = await getConfig()
    const customBase = `${await getBaseUrl()}campuses/${campusId}/infocategories`
    config.url = customBase
    try {
      const response = await axios(config)
      data = response.data.items
      await storeData(storageKey, data)
    } catch (error) {
      console.error('Error fetching info categories:', error)
      throw error
    }
  }
  return data
}

export const getInfoCategoryById = async id => {
  const storageKey = `infoCategory_${id}`
  let data = await getStoredData(storageKey)
  if (!data) {
    const config = await getConfig()
    const campusId = await getCampusId()
    config.url = `${await getBaseUrl()}campuses/${campusId}/infocategories/${id}`
    try {
      const response = await axios(config)
      data = response.data
      await storeData(storageKey, data)
    } catch (error) {
      console.error('Error fetching info category:', error)
      throw error
    }
  }
  return data
}
export const getFloors = async () => {
  const storageKey = 'floorsData'
  let floorsData = await getStoredData(storageKey)
  if (!floorsData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}floors`
    try {
      const response = await axios(config)
      floorsData = response.data.items
      await storeData(storageKey, floorsData)
    } catch (error) {
      console.error('Error fetching floors:', error)
      throw error
    }
  }
  return floorsData
}

export const getFloorById = async floorId => {
  const storageKey = `floor_${floorId}`
  let floorData = await getStoredData(storageKey)
  if (!floorData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}floors/${floorId}`
    try {
      const response = await axios(config)
      floorData = response.data
      await storeData(storageKey, floorData)
    } catch (error) {
      console.error('Error fetching floor:', error)
      throw error
    }
  }
  return floorData
}

export const getBuildings = async () => {
  const storageKey = 'buildingsData'
  let buildingsData = await getStoredData(storageKey)
  if (!buildingsData) {
    const campusId = await getCampusId()
    const config = await getConfig()
    config.url = `${await getBaseUrl()}buildings`
    try {
      let response = await axios(config)
      buildingsData = response.data.items.filter(building => building.campus?.id === campusId)
      await storeData(storageKey, buildingsData)
    } catch (error) {
      console.error('Error fetching buildings:', error)
      throw error
    }
  }
  return buildingsData
}

export const getBuildingById = async buildingId => {
  const storageKey = `building_${buildingId}`
  let buildingData = await getStoredData(storageKey)
  if (!buildingData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}/buildings/${buildingId}`
    try {
      let response = await axios(config)
      buildingData = response.data
      await storeData(storageKey, buildingData)
    } catch (error) {
      console.error('Error fetching building:', error)
      throw error
    }
  }
}

export const getPoisByBuildingId = async buildingId => {
  const storageKey = `poisByBuildingId_${buildingId}`
  let poisData = await getStoredData(storageKey)
  if (!poisData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}buildings/${buildingId}/pois`
    try {
      let response = await axios(config)
      poisData = response.data.items
      await storeData(storageKey, poisData)
    } catch (error) {
      console.error('Error fetching POIs by building:', error)
      throw error
    }
  }
  return poisData
}

export const getPoiCategories = async () => {
  const storageKey = 'poiCategoriesData'
  let categoriesData = await getStoredData(storageKey)
  if (!categoriesData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}poicategories`
    try {
      let response = await axios(config)
      categoriesData = response.data.items
      await storeData(storageKey, categoriesData)
    } catch (error) {
      console.error('Error fetching POI categories:', error)
      throw error
    }
  }
  return categoriesData
}

export const getPoisByCategoryId = async categoryId => {
  const storageKey = `poisByCategoryId_${categoryId}`
  let poisData = await getStoredData(storageKey)
  if (!poisData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}poicategories/${categoryId}/pois`
    try {
      let response = await axios(config)
      poisData = response.data.items
      await storeData(storageKey, poisData)
    } catch (error) {
      console.error('Error fetching POIs by category:', error)
      throw error
    }
  }
  return poisData
}

export const getPoiById = async poiId => {
  const storageKey = `poi_${poiId}`
  let poiData = await getStoredData(storageKey)
  if (!poiData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}pois/${poiId}`
    try {
      let response = await axios(config)
      poiData = response.data
      await storeData(storageKey, poiData)
    } catch (error) {
      console.error('Error fetching POI:', error)
      throw error
    }
  }
  return poiData
}
export const getPoiCategoryById = async categoryId => {
  const storageKey = `poiCategory_${categoryId}`
  let categoryData = await getStoredData(storageKey)
  if (!categoryData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}poicategories/${categoryId}`
    try {
      let response = await axios(config)
      categoryData = response.data
      await storeData(storageKey, categoryData)
    } catch (error) {
      console.error('Error fetching POI category:', error)
      throw error
    }
  }
  return categoryData
}

export const getPoiCategoriesByBuildingId = async buildingId => {
  const storageKey = `poiCategoriesByBuildingId_${buildingId}`
  let categoriesData = await getStoredData(storageKey)
  if (!categoriesData) {
    categoriesData = await getPoiCategories() // This function should already handle caching
    const poisResponses = await Promise.all(categoriesData.map(category => getPoisByCategoryId(category.id)))
    categoriesData = categoriesData
      .map((category, index) => {
        const activePois = poisResponses[index].filter(poi => poi.buildingId === buildingId && poi.active)
        return activePois.length > 0 ? { ...category, pois: activePois } : null
      })
      .filter(category => category !== null)
    await storeData(storageKey, categoriesData)
  }
  return categoriesData
}

export const getAllFiles = async () => {
  const storageKey = 'filesData'
  let filesData = await getStoredData(storageKey)
  if (!filesData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}files`
    try {
      let response = await axios(config)
      filesData = response.data.items
      await storeData(storageKey, filesData)
    } catch (error) {
      console.error('Error fetching files:', error)
      throw error
    }
  }
  return filesData
}

export const getFile = async id => {
  const storageKey = `fileData_${id}`
  let fileData = await getStoredData(storageKey)
  if (!fileData) {
    const config = await getConfig()
    const fileUrl = await getFileUrl()
    config.url = `${fileUrl}${id}`
    config.headers['x-ps-api-key'] = await getFileApiKey()
    config.responseType = 'blob'
    try {
      const response = await axios(config)
      fileData = response.data
      await storeData(storageKey, fileData)
    } catch (error) {
      console.error('Error fetching file:', error)
      throw error
    }
  }
  return fileData
}

export const deleteProfileImage = async (accountId, imageId) => {
  const config = await getConfig()
  config.url = `${await getBaseUrl()}userprofiles/${accountId}/files`
  config.method = 'DELETE'

  // Setting the payload for DELETE request as an array with the file ID
  config.data = [imageId]

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error deleting profile image:', error)
    throw error
  }
}
export const postProfileImage = async (userProfileId, fileId) => {
  const config = await getConfig()
  const payload = {
    files: [
      {
        id: fileId,
        type: 'profileImage',
      },
    ],
  }
  config.url = `${await getBaseUrl()}userprofiles/${userProfileId}/files`
  config.method = 'POST'
  config.data = payload
  config.headers['Content-Type'] = 'application/json'
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error updating profile image:', error.response?.data || error.message)
    throw error
  }
}

export const postFile = async (blob, type) => {
  const formData = new FormData()
  const config = await getConfig()
  formData.append('file', blob)
  formData.append('type', type)
  const fileUrl = await getFileUrl()
  const fileApiKey = await getFileApiKey()
  config.url = `${fileUrl}single`
  config.method = 'POST'
  config.data = formData
  config.headers['Content-Type'] = 'multipart/form-data'
  config.headers['x-ps-api-key'] = fileApiKey
  try {
    const response = await axios(config)
    console.log('Response from postFile:', response)
    return response.data
  } catch (error) {
    console.error('Error posting file:', error)
    throw error
  }
}

export const postMultipleFiles = async filesArray => {
  // Validate the input to be an array of files
  if (!Array.isArray(filesArray) || filesArray.length === 0) {
    throw new Error('Files array must contain at least one file.')
  }

  const formData = new FormData()

  // Append each file to formData
  filesArray.forEach((file, index) => {
    formData.append('files', file)
  })

  // Get necessary configuration
  const config = await getConfig()
  const fileUrl = await getFileUrl()
  const fileApiKey = await getFileApiKey()

  config.url = `${fileUrl}`
  config.method = 'POST'
  config.data = formData
  config.headers['Content-Type'] = 'multipart/form-data'
  config.headers['x-ps-api-key'] = fileApiKey

  try {
    const response = await axios(config)
    console.log('Response from postMultipleFiles:', response.data) // Log response to inspect the structure
    return response.data
  } catch (error) {
    console.error('Error uploading multiple files:', error.response?.data || error.message)
    throw error
  }
}

export const updateFile = async (id, payload) => {
  const config = await getConfig()
  const fileUrl = await getFileUrl()
  config.url = `${fileUrl}${id}`
  config.method = 'PUT'
  config.data = payload
  config.headers['Content-Type'] = 'application/json'
  config.headers['x-ps-api-key'] = await getFileApiKey()
  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.error('Error updating file:', error)
    throw error
  }
}

export const getDataFromBlob = myBlob => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(myBlob)
  })
}

export const readAsDataURL = file => {
  return new Promise((resolve, reject) => {
    const fileReaderInstance = new FileReader()
    fileReaderInstance.readAsDataURL(file)
    fileReaderInstance.onload = () => resolve(fileReaderInstance.result)
    fileReaderInstance.onerror = error => reject(error)
  })
}

export const getFileInfo = async id => {
  const storageKey = `fileInfo_${id}`
  let fileInfo = await getStoredData(storageKey)
  if (!fileInfo) {
    const config = await getConfig()
    const fileUrl = await getFileUrl()
    config.url = `${fileUrl}${id}/info`
    config.headers['x-ps-api-key'] = await getFileApiKey()
    try {
      const response = await axios(config)
      fileInfo = response.data
      await storeData(storageKey, fileInfo)
    } catch (error) {
      console.error('Error fetching file info:', error)
      throw error
    }
  }
  return fileInfo
}

export const getPois = async () => {
  const storageKey = 'poisData'
  let poisData = await getStoredData(storageKey)
  if (!poisData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}pois`
    try {
      let response = await axios(config) // Use the custom axios instance
      poisData = response.data.items
      await storeData(storageKey, poisData)
    } catch (error) {
      console.error('Error fetching POIs:', error)
      throw error
    }
  }
  return poisData
}

export const getReservationsByPoi = async (poiId, from, until) => {
  const config = await getConfig()
  let customBase = 'pois/' + poiId + '/reservations?from=' + from.toISOString() + '&until=' + until.toISOString()
  config.url = `${await getBaseUrl()}${customBase}`
  try {
    const response = await axios(config)
    return response.data.items
  } catch (error) {
    console.error('Error fetching reservations by POI:', error)
    throw error
  }
}

export const deleteRecurrenceAppointment = async id => {
  const config = await getConfig()
  let customBase = 'appointments/recurrence/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['url'] = customBase
  config['method'] = 'DELETE'

  return axios(config)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const deleteAppointment = async id => {
  const config = await getConfig()
  let customBase = 'appointments/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['url'] = customBase
  config['method'] = 'DELETE'

  return axios(config)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const deleteRecurrenceReservation = async id => {
  const config = await getConfig()
  let customBase = 'reservations/recurrence/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['url'] = customBase
  config['method'] = 'DELETE'

  return axios(config)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const deleteReservation = async id => {
  const config = await getConfig()
  let customBase = 'reservations/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'DELETE'

  return axios(config)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const getReservationById = async id => {
  const config = await getConfig()
  let customBase = 'reservations/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['url'] = customBase
  config['method'] = 'GET'

  return axios(config)
    .then(function (response) {
      if (response && response.data && response.status === 200) {
        return response.data
      }
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const getReservationsByCurrentUserByTime = async (from, until) => {
  const config = await getConfig()
  let customBase = 'reservations/by-current-user?from=' + from.toISOString() + '&until=' + until.toISOString()
  config.url = `${await getBaseUrl()}${customBase}`
  try {
    const response = await axios(config)
    return response.data.items
  } catch (error) {
    console.error('Error fetching reservations by current user by time:', error)
    throw error
  }
}

export const getAppointmentById = async id => {
  const config = await getConfig()
  let customBase = 'appointments/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['url'] = customBase
  config['method'] = 'GET'

  return axios(config)
    .then(function (response) {
      if (response && response.data && response.status === 200) {
        return response.data
      }
      return response
    })
    .catch(function (error) {
      console.log(error)
      throw error
    })
}

export const createReservation = async payload => {
  const config = await getConfig()
  let customBase = 'reservations'
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'POST'
  config['data'] = JSON.stringify(payload)
  console.log(await getBaseUrl())

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const createAppointment = async payload => {
  const config = await getConfig()
  let customBase = 'appointments'
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'POST'
  config['data'] = JSON.stringify(payload)

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const updateReservation = async (id, payload) => {
  const config = await getConfig()
  let customBase = 'reservations/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'PUT'
  config['data'] = JSON.stringify(payload)

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const updateAppointment = async (id, payload) => {
  const config = await getConfig()
  let customBase = 'appointments/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'PUT'
  config['data'] = JSON.stringify(payload)

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const deleteReservationById = async id => {
  const config = await getConfig()
  let customBase = 'reservations/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'DELETE'

  return axios(config)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const deleteAppointmentById = async id => {
  const config = await getConfig()
  let customBase = 'appointments/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'DELETE'

  return axios(config)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const updateUserProfile = async (id, payload) => {
  const config = await getConfig()
  let customBase = 'userprofiles/' + id
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'PUT'
  config['data'] = JSON.stringify(payload)

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const createUserProfile = async payload => {
  const config = await getConfig()
  let customBase = 'userprofiles'
  config.url = `${await getBaseUrl()}${customBase}`
  config['responseType'] = 'json'
  config['method'] = 'POST'
  config['data'] = JSON.stringify(payload)

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

<template>
  <div class="incident-creation">
    <!-- Toolbar -->
    <v-toolbar color="#ffffff" prominent elevation="3">
      <v-icon :color="primaryColor" class="toolbar-icon" size="30" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title style="margin-left: 1rem">Incidents</v-toolbar-title>
      <v-spacer></v-spacer>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <!-- Content when no incidents -->
    <v-container v-if="!hasIncidents" class="d-flex flex-column align-center justify-center no-incidents-container">
      <img :src="isMobile ? imageSrcMobile : imageSrcDesktop" class="no-incidents-image" />
      <v-text class="no-incidents-text">There is no incident created by you. To report an incident, please use the Create button.</v-text>
      <v-btn color="primary" @click="openCreateDialog">Create</v-btn>
    </v-container>

    <!-- Content when incidents exist -->
    <v-container v-else class="incidents-list-container">
      <v-list>
        <v-list-item v-for="incident in incidents" :key="incident.id" class="incident-list-item">
          <v-list-item-content>
            <v-list-item-title>Floor Name: {{ incident.floorName }}</v-list-item-title>
            <v-list-item-subtitle>Status: {{ incident.status }}</v-list-item-subtitle>
            <v-list-item-subtitle>Incident No: {{ incident.incidentNumber }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content class="incident-meta">
            <span>{{ incident.date }}</span>
            <v-btn icon @click="editIncident(incident.id)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>

    <!-- Create Incident Dialog -->
    <v-dialog v-model="createDialog" max-width="600px">
      <v-card>
        <v-card-title>Create an Incident</v-card-title>
        <v-card-subtitle>Send an incident or damage to the facility management.</v-card-subtitle>
        <v-card-text>
          <v-form>
            <v-text-field label="Set a Location" prepend-icon="mdi-map-marker" readonly @click="openMap" />
            <v-select label="Select a Category" :items="categories" prepend-icon="mdi-format-list-bulleted" v-model="selectedCategory" />
            <v-textarea label="Describe the Incident" prepend-icon="mdi-text" v-model="description" required />
            <v-file-input label="Upload Image" prepend-icon="mdi-camera" @click="uploadImage" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="createDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="submitIncident">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { defineComponent, ref, onMounted, computed } from 'vue'
  import imageSrcDesktop from '@/assets/General/no_booking_desktop.webp'
  import imageSrcMobile from '@/assets/General/no_booking_mobile.webp'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'

  export default defineComponent({
    name: 'Incidents',
    setup() {
      const logo = ref('')
      const incidents = ref([]) // Fetch this data from an API or state
      const createDialog = ref(false)
      const selectedCategory = ref(null)
      const description = ref('')
      const categories = ref(['Electrical', 'Plumbing', 'Other']) // Example categories
      const isMobile = computed(() => window.innerWidth <= 600)

      onMounted(async () => {
        logo.value = await getToolbarLogoImage()
        // Fetch incidents data here and set to incidents array
      })

      const hasIncidents = computed(() => incidents.value.length > 0)

      const openCreateDialog = () => {
        createDialog.value = true
      }

      const openMap = () => {
        // Handle opening map here
      }

      const uploadImage = () => {
        // Handle image upload using Capacitor (camera access for mobile)
      }

      const submitIncident = () => {
        // Handle incident submission here
        createDialog.value = false
      }

      return {
        logo,
        isMobile,
        incidents,
        hasIncidents,
        createDialog,
        selectedCategory,
        description,
        categories,
        openCreateDialog,
        openMap,
        uploadImage,
        submitIncident,
        imageSrcDesktop,
        imageSrcMobile,
      }
    },
  })
</script>

<style scoped>
  .incident-creation {
    padding: 16px;
  }

  .no-incidents-container {
    text-align: center;
  }

  .no-incidents-image {
    max-width: 100%;
    opacity: 0.7;
    margin-bottom: 20px;
  }

  .no-incidents-text {
    margin-bottom: 20px;
  }

  .incidents-list-container {
    margin-top: 20px;
  }

  .incident-list-item {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  .incident-list-item:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }

  .incident-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .v-btn {
    transition: all 0.3s ease;
  }
  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 0.5rem;
  }
</style>

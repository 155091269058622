<template>
  <div class="create-edit-infoitem">
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Create Event or News Post' }}
      </v-toolbar-title>
    </v-toolbar>
    <Loader :loading="showLoader" />
    <div class="create-edit-info-item">
      <v-container fluid>
        <v-form ref="form" lazy-validation>
          <!-- Title -->
          <v-text-field
            v-model="title"
            :rules="[rules.required, rules.titleLimit]"
            label="Title"
            prepend-inner-icon="mdi-format-title"
            variant="underlined"
            color="primary"
            counter="100"
            required
          ></v-text-field>

          <!-- Category Dropdown -->
          <v-select
            v-model="category"
            :items="categories"
            label="Category"
            prepend-inner-icon="mdi-tag"
            variant="underlined"
            color="primary"
            required
          ></v-select>

          <!-- Event Fields -->
          <template v-if="category === 'Event'">
            <!-- Event Date Picker -->
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  v-model="eventDateText"
                  label="Event Date"
                  prepend-inner-icon="mdi-calendar"
                  variant="underlined"
                  color="primary"
                  readonly
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                max-width="250px"
                max-height="350px"
                min-width="250px"
                elevation="3"
                rounded="lg"
                hide-header
                show-adjacent-months
                v-model="eventDatePickerModel"
                @update:modelValue="updateEventDate"
              ></v-date-picker>
            </v-menu>

            <!-- Short Text Content for Event -->
            <v-textarea
              v-model="shortTextContent"
              :rules="[rules.required, rules.lineLimit]"
              label="Short Text Content"
              prepend-inner-icon="mdi-text"
              variant="underlined"
              color="primary"
              rows="1"
              counter="250"
              required
            ></v-textarea>
          </template>

          <!-- News and Announcement Fields -->
          <template v-if="category === 'News' || category === 'Announcement'">
            <!-- Short Description (v-text-field) -->
            <v-text-field
              v-model="shortDescription"
              :rules="[rules.required, rules.shortTextLimit]"
              label="Short Description"
              prepend-inner-icon="mdi-text"
              variant="underlined"
              color="primary"
              counter="150"
              required
            ></v-text-field>

            <!-- Full Description (v-textarea) -->
            <v-textarea
              v-model="textContent"
              label="Description"
              prepend-inner-icon="mdi-format-align-left"
              variant="underlined"
              color="primary"
              rows="5"
              required
            ></v-textarea>
          </template>

          <!-- Upload Section (Hidden for Event Category) -->
          <div v-if="category !== 'Event'" class="upload-section">
            <h4>Images and Files</h4>

            <!-- Title Image -->
            <v-file-input
              v-model="titleImage"
              label="Title Image"
              accept="image/png, image/jpeg"
              prepend-icon="mdi-image"
              variant="underlined"
              color="primary"
            >
              <template v-slot:selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                  <v-chip class="me-2" color="primary" size="small" label>
                    {{ fileName }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>
            <p class="file-info-text">File format: .png, .jpg. Size: At maximum 1024 pixels in width (optimum: 750 x 498 pixels).</p>

            <!-- Additional Images -->
            <v-file-input
              v-model="additionalImages"
              label="Additional Images"
              accept="image/png, image/jpeg"
              prepend-icon="mdi-folder-multiple-image"
              variant="underlined"
              color="primary"
              multiple
              counter
              show-size
            >
              <template v-slot:selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                  <v-chip class="me-2" color="primary" size="small" label>
                    {{ fileName }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>

            <!-- Files (PDF only) -->
            <v-file-input
              v-model="files"
              label="Files (PDF only)"
              accept="application/pdf"
              prepend-icon="mdi-file-pdf-box"
              variant="underlined"
              color="primary"
              multiple
              counter
              show-size
            >
              <template v-slot:selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                  <v-chip class="me-2" color="primary" size="small" label>
                    {{ fileName }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>
            <p class="file-info-text">PDF only. Maximum 50 MB per file.</p>
          </div>

          <!-- Submit Button -->
          <v-btn @click="submitForm" color="primary" :disabled="!formValid" class="mt-4">Submit</v-btn>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script>
  import { defineComponent, onMounted, ref, watch, computed } from 'vue'
  import Swal from 'sweetalert2'
  import { getToolbarLogoImage } from '@/assets/Branding/branding'
  import moment from 'moment'
  import Loader from '@/components/general/Loader.vue'
  import {
    createInfoItem,
    linkInfoItemToCategory,
    postFile,
    linkInfoItemTitleImage,
    postMultipleFiles,
    linkInfoTitleExtraImages,
    linkInfoItemAttachments,
    deleteInfoItem,
  } from '@/controllers/BaseController'

  export default defineComponent({
    name: 'CreateEditInfoItem',
    components: {
      Loader,
    },
    setup() {
      const logo = ref('')
      const title = ref('')
      const category = ref('')
      const eventDateText = ref('')
      const eventDatePickerModel = ref(new Date())
      const shortDescription = ref('')
      const shortTextContent = ref('')
      const textContent = ref('')
      const titleImage = ref(null)
      const additionalImages = ref([])
      const files = ref([])

      const categories = ref(['Event', 'News', 'Announcement'])
      const menu = ref(false)
      const showLoader = ref(false)
      const form = ref(null)

      const rules = {
        required: value => !!value || 'Required.',
        lineLimit: value => value.split('\n').length <= 1 || 'Only 1 line allowed.',
        titleLimit: value => value.length <= 100 || 'Maximum 100 characters allowed.',
        shortTextLimit: value => value.length <= 150 || 'Maximum 150 characters allowed.',
      }

      watch(category, newValue => {
        if (newValue) {
          title.value = ''
          eventDateText.value = ''
          eventDatePickerModel.value = new Date()
          shortTextContent.value = ''
          shortDescription.value = ''
          textContent.value = ''
          titleImage.value = null
          additionalImages.value = []
          files.value = []
        }
      })

      const formValid = computed(() => {
        return (
          title.value &&
          category.value &&
          (category.value !== 'Event' ? shortDescription.value : true) &&
          (category.value !== 'Event' ? textContent.value : shortTextContent.value) &&
          (category.value !== 'News' && category.value !== 'Announcement' ? true : titleImage.value) &&
          true // add any additional form validation checks here
        )
      })

      const updateEventDate = newDate => {
        eventDatePickerModel.value = newDate
        eventDateText.value = moment(newDate).format('DD-MM-YYYY')
        menu.value = false
      }

      const resizeImage = (file, maxWidth = 750, maxHeight = 498) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = URL.createObjectURL(file)
          img.onload = () => {
            const canvas = document.createElement('canvas')
            let width = img.width
            let height = img.height

            if (width > maxWidth) {
              height *= maxWidth / width
              width = maxWidth
            }
            if (height > maxHeight) {
              width *= maxHeight / height
              height = maxHeight
            }

            canvas.width = width
            canvas.height = height

            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)

            canvas.toBlob(blob => {
              if (blob) {
                resolve(blob)
              } else {
                reject(new Error('Image resize failed.'))
              }
            }, 'image/png')
          }
          img.onerror = () => reject(new Error('Image load failed.'))
        })
      }

      const submitForm = async () => {
        if (!form.value) return

        // Explicitly validate the form
        const isFormValid = await form.value.validate()

        if (!isFormValid) {
          return
        }

        showLoader.value = true

        // Gather all values into an infoItem object
        const infoItemPayload = {
          title: title.value,
          active: true,
          shortText: category.value !== 'Event' ? shortDescription.value : moment(eventDateText.value).format('DD-MM-YYYY'),
          text: category.value !== 'Event' ? textContent.value : shortTextContent.value,
          isNews: category.value === 'News',
          infoCategory: category.value,
          infoCategoryId: '562ee09d-fe7f-4942-85eb-d71f56d5664d', // Replace with the actual category ID
        }

        let infoItemId

        try {
          // Step 1: Create Info Item
          const infoItem = await createInfoItem(infoItemPayload)
          infoItemId = infoItem.id

          // Step 2: Link Info Item to Category
          await linkInfoItemToCategory(infoItemId, infoItemPayload.infoCategoryId)

          // Step 3: Handle Title Image (if exists)
          if (titleImage.value) {
            const titleImageBlob = await resizeImage(titleImage.value)
            const titleImageResponse = await postFile(titleImageBlob, 'image/png')
            await linkInfoItemTitleImage(infoItemId, titleImageResponse.id)
          }

          // Step 4: Handle Additional Images (if any)
          if (additionalImages.value.length > 0) {
            const additionalBlobs = await Promise.all(additionalImages.value.map(file => resizeImage(file)))
            const additionalImagesResponse = await postMultipleFiles(additionalBlobs)

            // Use the array response directly
            const fileIdsArray = additionalImagesResponse.map(file => file.id)
            await linkInfoTitleExtraImages(infoItemId, fileIdsArray)
          }

          // Step 5: Handle PDF Files (if any)
          if (files.value.length > 0) {
            const pdfBlobs = files.value
            const pdfResponse = await postMultipleFiles(pdfBlobs)

            // Use the array response directly
            const pdfFileIdsArray = pdfResponse.map(file => file.id)
            await linkInfoItemAttachments(infoItemId, pdfFileIdsArray)
          }

          // Success Notification
          Swal.fire({
            title: 'Success!',
            text: 'Info Item successfully created.',
            icon: 'success',
            confirmButtonText: 'OK',
          })
        } catch (error) {
          console.error('Error during the info item creation:', error)

          // Rollback if any step fails
          if (infoItemId) {
            await deleteInfoItem(infoItemId)
          }

          // Error Notification
          Swal.fire({
            title: 'Error!',
            text: 'There was an error creating the Info Item. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        } finally {
          showLoader.value = false
        }
      }

      onMounted(async () => {
        try {
          logo.value = await getToolbarLogoImage()
          category.value = 'Event'
        } catch (error) {
          console.error('Error fetching data:', error)
        } finally {
          showLoader.value = false
        }
      })

      return {
        logo,
        title,
        category,
        eventDateText,
        eventDatePickerModel,
        shortDescription,
        shortTextContent,
        textContent,
        titleImage,
        additionalImages,
        files,
        formValid,
        categories,
        menu,
        showLoader,
        rules,
        updateEventDate,
        submitForm,
        resizeImage,
        form,
      }
    },
  })
</script>

<style scoped>
  .create-edit-infoitem {
    padding: 1rem;
  }
  ::v-deep .v-date-picker .mdi-chevron-right {
    margin-left: -2rem;
    /* Add any other style customizations here */
  }

  /* Example of more targeted customization, if necessary */
  ::v-deep .v-date-picker .v-icon.mdi-chevron-right {
    margin-left: -2rem;
  }
  .custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
  }

  .custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px);
  }
  .toolbar-icon {
    height: 30px;
    align-self: center;
  }

  .create-edit-info-item {
    max-width: 900px;
    margin: 2rem auto;
    background: white;
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  }

  .upload-section {
    margin: 2rem 0;
  }

  .file-info-text {
    font-size: 0.85rem;
    color: #666;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  h4 {
    font-weight: bold;
    color: #007bff;
    margin-bottom: 1rem;
  }

  .v-text-field,
  .v-textarea,
  .v-file-input,
  .v-select,
  .v-html-editor {
    margin-bottom: 1.5rem;
  }

  /* Added styles to make the chips more visible */
  .v-chip {
    background-color: #e0f7fa !important;
    color: #007bff !important;
    margin: 4px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .create-edit-info-item {
      padding: 1.5rem;
      max-width: 100%;
    }

    .custom-toolbar-title {
      font-size: 1.4rem;
    }
  }
  @media (max-width: 640px) {
    .custom-toolbar-title {
      font-size: large;
    }
  }
</style>

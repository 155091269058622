// axiosWrapper.js
/* eslint-disable no-useless-escape */
import axios from 'axios'
import { isStaingOrQa } from '@/controllers/BaseController'
import moment from 'moment'
import {
  mockCreateReservation,
  mockCreateAppointment,
  getAllReservations,
  getAppointmentById,
  getReservationById,
  deleteAppointmentById,
  deleteRecurrenceAppointmentById,
  deleteRecurrenceReservationById,
  deleteReservationById,
} from '@/dummydata/mockBookings'
import router from '@/router'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  async config => {
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
    if (await isStaingOrQa()) {
      if (config.url.endsWith('/v3/poicategories')) {
        const { categories } = await import('@/dummydata/categories')
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: categories },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (/\/v3\/poicategories\/[a-f0-9\-]{36}$/.test(config.url)) {
        const categoryId = config.url.split('/').slice(-1)[0]
        const { getPoiCategoryById } = await import('@/dummydata/categories')
        const category = getPoiCategoryById(categoryId)
        return {
          ...config,
          adapter: async () => {
            return {
              data: category,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }

      if (/\/v3\/poicategories\/[^\/]+\/pois$/.test(config.url)) {
        const categoryId = config.url.split('/').slice(-2)[0]
        const { getPoisByCategoryId } = await import('@/dummydata/pois')
        const pois = getPoisByCategoryId(categoryId)
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: pois },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }

      if (/\/v3\/pois\/[a-f0-9\-]{36}$/.test(config.url)) {
        const poiId = config.url.split('/').slice(-1)[0]
        const { getPoiById } = await import('@/dummydata/pois')
        const poi = getPoiById(poiId)
        return {
          ...config,
          adapter: async () => {
            return {
              data: poi,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (/\/v3\/floors$/.test(config.url)) {
        const { floors } = await import('@/dummydata/floors')
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: floors },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (/\/v3\/floors\/[a-f0-9\-]{36}$/.test(config.url)) {
        const floorId = config.url.split('/').slice(-1)[0]
        const { getFloorById } = await import('@/dummydata/floors')
        const floor = getFloorById(floorId)
        return {
          ...config,
          adapter: async () => {
            return {
              data: floor,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (
        /\/v1\/files\/[a-f0-9\-]{36}$/.test(config.url) &&
        config.method.toUpperCase() === 'GET' &&
        !window.location.pathname.includes('users') &&
        router.currentRoute.value.name !== 'UserProfile' &&
        router.currentRoute.value.name !== 'MenuView' &&
        router.currentRoute.value.name !== 'ChatUsersList' &&
        router.currentRoute.value.name !== 'Chat' &&
        router.currentRoute.value.name !== 'InfoCenterView' &&
        router.currentRoute.value.name !== 'HomeView'
      ) {
        console.log(router.currentRoute.value.name)
        const fileId = config.url.split('files/')[1]
        const { getAlternateFileUrl, getFileApiKey } = await import('./BaseController')
        console.log('Intercepting Files')
        try {
          const [alternateFileUrl, fileApiKey] = await Promise.all([getAlternateFileUrl(), getFileApiKey()])

          const response = await axios({
            ...config,
            url: `${alternateFileUrl}${fileId}`,
            headers: {
              ...config.headers,
              'x-ps-api-key': fileApiKey,
            },
          })

          return {
            ...config,
            adapter: async () => ({
              data: response.data,
              status: response.status,
              statusText: response.statusText,
              headers: response.headers,
              config,
            }),
          }
        } catch (error) {
          return Promise.reject(error)
        }
      }
      if (/\/v3\/campuses(\?.*)?$/.test(config.url)) {
        const { campuses } = await import('@/dummydata/campuses')
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: campuses },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (/\/v3\/buildings(\?.*)?$/.test(config.url)) {
        const { buildings } = await import('@/dummydata/buildings')
        console.log('Intercepting Buildings')
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: buildings },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      //***************************FOR RESERVATIONS*********************************** */
      if (/\/v3\/pois\/[a-f0-9\-]{36}\/reservations(\?.*)?$/.test(config.url)) {
        // Extract the POI ID using a regular expression
        const poiIdMatch = config.url.match(/\/v3\/pois\/([a-f0-9\-]{36})\/reservations/)
        const poiId = poiIdMatch ? poiIdMatch[1] : null

        // Extract the query parameters using URLSearchParams
        const urlParams = new URLSearchParams(config.url.split('?')[1])
        const fromTime = urlParams.get('from')
        const untilTime = urlParams.get('until')
        let { reservations } = await import('@/dummydata/reservations')

        if (poiId) {
          reservations = reservations.filter(
            reservation =>
              reservation.poiId === poiId &&
              moment(reservation.startTime).isSameOrAfter(fromTime) &&
              moment(reservation.endTime).isSameOrBefore(untilTime)
          )
        }
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: reservations },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }

      if (config.method.toUpperCase() === 'POST') {
        if (config.url.includes('/v3/reservations')) {
          const reservationData = config.data

          // Simulate delay
          await delay(2000)

          // Use the mock service to create a reservation
          const reservationResponse = await mockCreateReservation(reservationData)

          // Mock the response to return the generated reservation
          return {
            ...config,
            adapter: async () => {
              return {
                data: reservationResponse,
                status: 201,
                statusText: 'Created',
                headers: config.headers,
                config,
              }
            },
          }
        }

        if (config.url.includes('/v3/appointments')) {
          const appointmentData = config.data

          // Simulate delay
          await delay(2000)

          // Use the mock service to create an appointment
          const appointmentResponse = await mockCreateAppointment(appointmentData)

          // Mock the response to return the generated appointment
          return {
            ...config,
            adapter: async () => {
              return {
                data: appointmentResponse,
                status: 201,
                statusText: 'Created',
                headers: config.headers,
                config,
              }
            },
          }
        }
      }

      if (/reservations\/by-current-user\?from=.*&until=.*/.test(config.url)) {
        console.log('Intercepting Reservations by Current User')
        const urlParams = new URLSearchParams(config.url.split('?')[1])
        const fromTime = moment(urlParams.get('from'))
        const untilTime = moment(urlParams.get('until'))

        // Get all reservations from IndexedDB
        const reservations = await getAllReservations(fromTime, untilTime)
        console.log(reservations)
        // Filter reservations based on the provided date range
        const filteredReservations = reservations.filter(
          reservation => moment(reservation.startTime).isSameOrAfter(fromTime) && moment(reservation.endTime).isSameOrBefore(untilTime)
        )

        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: filteredReservations },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }

      // Interceptor for fetching an appointment by ID
      if (/appointments\/[a-f0-9\-]{36}$/.test(config.url) && config.method === 'get') {
        const appointmentId = config.url.split('/').slice(-1)[0]

        // Get the appointment from IndexedDB by ID
        let appointment = await getAppointmentById(appointmentId)

        return {
          ...config,
          adapter: async () => {
            return {
              data: appointment,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }

      if (/reservations\/[a-f0-9\-]{36}/.test(config.url) && config.method === 'get') {
        const reservationId = config.url.split('/').pop()
        console.log('Intercepting Reservation by ID')

        // Get the reservation by ID from IndexedDB
        const reservation = await getReservationById(reservationId)

        return {
          ...config,
          adapter: async () => ({
            data: reservation,
            status: 200,
            statusText: 'OK',
            headers: config.headers,
            config,
          }),
        }
      }

      // Reservation Update Interceptor
      if (config.method.toUpperCase() === 'PUT' && /reservations\/[a-f0-9\-]{36}/.test(config.url)) {
        const reservationId = config.url.split('/').pop() // Extract the reservation ID from the URL
        const payload = JSON.parse(config.data) // Parse the payload data

        console.log('Intercepting Reservation Update')

        try {
          // Import the updateReservation function
          const { updateReservation } = await import('@/dummydata/mockBookings')

          // Update the reservation with the payload data
          const updatedReservation = await updateReservation(reservationId, payload)
          await delay(2000)

          // Return a response with the updated reservation
          return {
            ...config,
            adapter: async () => ({
              data: updatedReservation,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        } catch (error) {
          console.error('Error updating reservation:', error)
          return {
            ...config,
            adapter: async () => ({
              status: 500,
              statusText: 'Internal Server Error',
              headers: config.headers,
              config,
            }),
          }
        }
      }

      // Appointment Update Interceptor
      if (config.method.toUpperCase() === 'PUT' && /appointments\/[a-f0-9\-]{36}/.test(config.url)) {
        const appointmentId = config.url.split('/').pop() // Extract the appointment ID from the URL
        const payload = JSON.parse(config.data) // Parse the payload data

        try {
          // Import the updateAppointment function
          const { updateAppointment } = await import('@/dummydata/mockBookings')
          console.log('Intercepting Appointment Update')

          // Update the appointment with the payload data
          const updatedAppointment = await updateAppointment(appointmentId, payload)
          await delay(2000)
          // Return a response with the updated appointment
          return {
            ...config,
            adapter: async () => ({
              data: updatedAppointment,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        } catch (error) {
          console.error('Error updating appointment:', error)
          return {
            ...config,
            adapter: async () => ({
              status: 500,
              statusText: 'Internal Server Error',
              headers: config.headers,
              config,
            }),
          }
        }
      }

      // Interceptor to delete a recurrence appointment
      if (config.method.toUpperCase() === 'DELETE' && /appointments\/recurrence\/[a-f0-9\-]{36}/.test(config.url)) {
        const recurringId = config.url.split('/').pop()

        try {
          await deleteRecurrenceAppointmentById(recurringId)
          await delay(2000)
          return {
            ...config,
            adapter: async () => ({
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        } catch (error) {
          console.error('Error deleting recurrence appointment:', error)
          return {
            ...config,
            adapter: async () => ({
              status: 500,
              statusText: 'Internal Server Error',
              headers: config.headers,
              config,
            }),
          }
        }
      }

      // Interceptor to delete a single appointment
      if (config.method.toUpperCase() === 'DELETE' && /appointments\/[a-f0-9\-]{36}/.test(config.url)) {
        const appointmentId = config.url.split('/').pop()

        try {
          await deleteAppointmentById(appointmentId)
          await delay(2000)
          return {
            ...config,
            adapter: async () => ({
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        } catch (error) {
          console.error('Error deleting appointment:', error)
          return {
            ...config,
            adapter: async () => ({
              status: 500,
              statusText: 'Internal Server Error',
              headers: config.headers,
              config,
            }),
          }
        }
      }

      // Interceptor to delete a recurrence reservation
      if (config.method.toUpperCase() === 'DELETE' && /reservations\/recurrence\/[a-f0-9\-]{36}/.test(config.url)) {
        const recurringId = config.url.split('/').pop()

        try {
          await deleteRecurrenceReservationById(recurringId)
          await delay(2000)
          return {
            ...config,
            adapter: async () => ({
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        } catch (error) {
          console.error('Error deleting recurrence reservation:', error)
          return {
            ...config,
            adapter: async () => ({
              status: 500,
              statusText: 'Internal Server Error',
              headers: config.headers,
              config,
            }),
          }
        }
      }

      // Interceptor to delete a single reservation
      if (config.method.toUpperCase() === 'DELETE' && /reservations\/[a-f0-9\-]{36}/.test(config.url)) {
        const reservationId = config.url.split('/').pop()

        try {
          await deleteReservationById(reservationId)
          await delay(2000)
          return {
            ...config,
            adapter: async () => ({
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        } catch (error) {
          console.error('Error deleting reservation:', error)
          return {
            ...config,
            adapter: async () => ({
              status: 500,
              statusText: 'Internal Server Error',
              headers: config.headers,
              config,
            }),
          }
        }
      }

      //****************************************************************************** */

      ///************************ For Hardwares******************* */

      const hardwareTypes = {
        BLINDS: 'blinds',
        LIGHT: 'lights',
        VENTILATOR: 'ventilators',
        HEATER: 'heaters',
      }

      if (config.url.includes('hardwares/by-type') && config.method === 'get') {
        console.log('Intercepting Hardwares')
        const type = config.url.split('type=')[1]
        const hardwareKey = hardwareTypes[type]

        if (hardwareKey) {
          const { [hardwareKey]: items } = await import('@/dummydata/hardwares')
          return {
            ...config,
            adapter: async () => ({
              data: { items },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        }
      }

      if (config.url.includes('elevators') && config.method === 'get') {
        const { elevators } = await import('@/dummydata/elevators')
        return {
          ...config,
          adapter: async () => ({
            data: { items: elevators },
            status: 200,
            statusText: 'OK',
            headers: config.headers,
            config,
          }),
        }
      }

      //***************************************************************************** */

      //************************* FOR INFO ITEMS************************************************** */
      //***************************************************************************** */
      if (/\/v3\/campuses\/[a-f0-9\-]{36}\/infoitems$/.test(config.url)) {
        let { campuses } = await import('@/dummydata/campuses')
        let campusId = campuses.find(c => c.name === 'Hauptstandort').id
        config.url = config.url.replace(/\/v3\/campuses\/[a-f0-9\-]{36}\//, `/v3/campuses/${campusId}/`)
        return config
      }

      if (/\/v3\/campuses\/[a-f0-9\-]{36}\/infocategories\/[a-f0-9\-]{36}$/.test(config.url)) {
        console.log('Intercepting Info Categories')
        let { campuses } = await import('@/dummydata/campuses')
        let newCampusId = campuses.find(c => c.name === 'Hauptstandort').id
        config.url = config.url.replace(/\/v3\/campuses\/[a-f0-9\-]{36}\//, `/v3/campuses/${newCampusId}/`)
        return config
      }

      //************************************************************************************************ */
      if (window.location.pathname.includes('/room-control') && config.url.endsWith('pois') && config.method === 'get') {
        console.log('Entercepting Room Control Pois')
        const { getStoredItem } = await import('@/services/utils')
        const { getBuildings } = await import('./BaseController')

        let storedBuilding = await getStoredItem('selectedBuilding')
        let buildings = await getBuildings()
        let selectedBuilding = buildings.find(b => b.name === storedBuilding)
        const { pois } = await import('@/dummydata/pois')
        const updatedPois = pois.map(poi => ({
          ...poi,
          buildingId: selectedBuilding.id,
          building: { ...poi.building, id: selectedBuilding.id },
        }))
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: updatedPois },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (window.location.pathname.includes('/room-control') && config.url.includes('hardwares/by-type') && config.method === 'get') {
        const type = config.url.split('type=')[1]
        let items = []
        if (type === 'BLINDS') {
          const { blinds } = await import('@/dummydata/hardwares')
          items = blinds
        }
        if (type === 'LIGHT') {
          const { lights } = await import('@/dummydata/hardwares')
          items = lights
        }
        if (type === 'VENTILATOR') {
          const { ventilators } = await import('@/dummydata/hardwares')
          items = ventilators
        }
        if (type === 'HEATER') {
          const { heaters } = await import('@/dummydata/hardwares')
          items = heaters
        }
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (window.location.pathname.includes('/elevator') && config.url.includes('elevators') && config.method === 'get') {
        const { elevators } = await import('@/dummydata/elevators')
        return {
          ...config,
          adapter: async () => {
            return {
              data: { items: elevators },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (window.location.pathname.includes('/room-control') && config.url.includes('poicategories') && config.method === 'get') {
        const categoryId = config.url.split('poicategories/')[1]
        const { getPoiCategoryById } = await import('@/dummydata/categories')
        const category = getPoiCategoryById(categoryId)
        return {
          ...config,
          adapter: async () => {
            return {
              data: category,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }

      if (window.location.pathname.includes('/room-control') && config.url.includes('pois') && config.method === 'get') {
        const poiId = config.url.split('pois/')[1]
        const { getPoiById } = await import('@/dummydata/pois')
        const category = getPoiById(poiId)
        return {
          ...config,
          adapter: async () => {
            return {
              data: category,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }

      if (window.location.pathname.includes('/room-control') && config.url.includes('floors/') && config.method === 'get') {
        const floorId = config.url.split('floors/')[1]
        const { getFloorById } = await import('@/dummydata/floors')
        const floor = getFloorById(floorId)
        return {
          ...config,
          adapter: async () => {
            return {
              data: floor,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }
          },
        }
      }
      if (window.location.pathname.includes('/room-control') && config.url.includes('files/') && config.method === 'get') {
        const fileId = config.url.split('files/')[1]
        const { getAlternateFileUrl, getFileApiKey } = await import('./BaseController')
        const alternateFileUrl = await getAlternateFileUrl()
        const fileApiKey = await getFileApiKey()
        const fileConfig = {
          ...config,
          url: `${alternateFileUrl}${fileId}`,
          headers: {
            ...config.headers,
            'x-ps-api-key': fileApiKey,
          },
        }
        try {
          const response = await axios(fileConfig)
          return {
            ...config,
            adapter: async () => {
              return {
                data: response.data,
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                config,
              }
            },
          }
        } catch (error) {
          return Promise.reject(error)
        }
      }
      if (window.location.pathname.includes('/menu/booking-management')) {
        if (config.url.includes('poicategories') && config.url.slice(-5) !== '/pois' && config.method === 'get') {
          const { categories } = await import('@/dummydata/categories')
          return {
            ...config,
            adapter: async () => ({
              data: { items: categories },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        }

        if (config.url.includes('floors/') && config.method === 'get') {
          const floorId = config.url.split('floors/')[1]
          const { getFloorById } = await import('@/dummydata/floors')
          const floor = getFloorById(floorId)
          return {
            ...config,
            adapter: async () => ({
              data: floor,
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        }

        if (config.url.includes('poicategories') && config.url.slice(-5) === '/pois' && config.method === 'get') {
          const categoryId = config.url.match(/poicategories\/(.*?)\/pois/)[1]
          const { getPoisByCategoryId } = await import('@/dummydata/pois')
          const pois = getPoisByCategoryId(categoryId)
          return {
            ...config,
            adapter: async () => ({
              data: { items: pois },
              status: 200,
              statusText: 'OK',
              headers: config.headers,
              config,
            }),
          }
        }

        // if (config.url.includes('reservations') && config.method === 'get') {
        //   const poiId = config.url.match(/pois\/(.*?)\/reservations/)[1]
        //   // Extract query parameters
        //   const urlParams = new URLSearchParams(config.url.split('?')[1])
        //   const from = urlParams.get('from')
        //   const until = urlParams.get('until')

        //   // Import reservations data (assuming it's a JSON file)
        //   const reservationsData = await import('@/dummydata/reservations.json')
        //   let reservations = reservationsData.reservations

        //   // Filter the reservations based on poiId, from, and until
        //   const filteredReservations = reservations.filter(reservation => {
        //     const reservationStart = moment(reservation.startTime)
        //     const reservationEnd = moment(reservation.endTime)
        //     const fromTime = moment(from)
        //     const untilTime = moment(until)

        //     return reservation.poiId === poiId && reservationStart.isSameOrAfter(fromTime) && reservationEnd.isSameOrBefore(untilTime)
        //   })

        //   return {
        //     ...config,
        //     adapter: async () => ({
        //       data: { items: filteredReservations },
        //       status: 200,
        //       statusText: 'OK',
        //       headers: config.headers,
        //       config,
        //     }),
        //   }
        // }
      }
    }
    return config
  },
  error => Promise.reject(error)
)

export default axiosInstance
